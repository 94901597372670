<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-card title="فروش ها" class="mt-3">
      <b-button
        v-b-toggle.filterBox
        variant="primary"
        class="mb-3 position-absolute"
        style="top:20px; left: 20px;"
        size="sm"
        ><i class="fa fa-bars"></i
      ></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title">شماره فاکتور</label>
                  <b-form-input
                    id="title"
                    v-model="searchParams.invoice_number"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group label="نام خریدار" label-for="customer">
                  <b-form-input
                    id="customer"
                    v-model="searchParams.customer"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="0">جدید</b-form-select-option>
                    <b-form-select-option value="1"
                      >در حال انتظار</b-form-select-option
                    >
                    <b-form-select-option value="2"
                      >تایید شده</b-form-select-option
                    >
                    <b-form-select-option value="3"
                      >در حال ارسال</b-form-select-option
                    >
                    <b-form-select-option value="4"
                      >تحویل داده شده</b-form-select-option
                    >
                    <b-form-select-option value="5"
                      >کنسل شده</b-form-select-option
                    >
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="تاریخ شروع" label-for="start_date">
                  <date-picker
                    type="datetime"
                    v-model="searchParams.start_date"
                    format="jYYYY-jMM-jDD HH:mm"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="تاریخ پایان" label-for="end_date">
                  <date-picker
                    type="datetime"
                    v-model="searchParams.end_date"
                    format="jYYYY-jMM-jDD HH:mm"
                  />
                </b-form-group>
              </div>
            </div>
            <button
              class="btn btn-primary btn-sm float-left"
              type="submit"
              :disabled="disabled"
            >
              جست و جو
            </button>
          </form>
        </b-card>
      </b-collapse>
      <b-table
        responsive
        striped
        hover
        v-if="items.data"
        :fields="table_fields"
        :items="items.data.data"
      >
        <template v-slot:cell(status)="data">
          <div>
            <p
              v-if="data.item.status.status == '0'"
              class="p-1 bg-secondary text-center small rounded"
            >
              جدید
            </p>
            <p
              v-else-if="data.item.status.status == '1'"
              class="p-1 bg-warning text-center small rounded"
            >
              در حال انتظار
            </p>
            <p
              v-else-if="data.item.status.status == '2'"
              class="p-1 bg-info text-center small rounded"
            >
              تایید شده
            </p>
            <p
              v-else-if="data.item.status.status == '3'"
              class="p-1 bg-primary text-center small rounded"
            >
              در حال ارسال
            </p>
            <p
              v-else-if="data.item.status.status == '4'"
              class="p-1 bg-success text-center small rounded"
            >
              تحویل داده شده
            </p>
            <p
              v-else-if="data.item.status.status == '5'"
              class="p-1 bg-danger text-center small rounded"
            >
              کنسل شده
            </p>
          </div>
        </template>
        <template v-slot:cell(invoice_number)="data">
          <router-link
            class="link btn-link"
            :to="'/admin/invoices/' + data.item.id"
            >#{{ data.item.invoice_number }}</router-link
          >
        </template>

        <template v-slot:cell(customer)="data">
          <router-link
            :to="'/admin/customers?id=' + data.item.customer.id"
            v-if="
              data.item.customer &&
                data.item.customer.first_name != null &&
                data.item.customer.last_name != null
            "
            >{{
              data.item.customer.first_name +
                " " +
                data.item.customer.last_name +
                " | دریافت کننده : " +
                data.item.name
            }}</router-link
          >
          <router-link
            :to="'/admin/customers?id=' + data.item.customer.id"
            v-else
          >
            {{ "دریافت کننده : " + data.item.name }}</router-link
          >
        </template>

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>
        <template v-slot:cell(gateway)="data">
          <span>
            {{ data.item.transaction ? data.item.transaction.gateway : "" }}
          </span>
        </template>

        <template v-slot:cell(AmountOrders)="data">
          {{ numberFormat(data.item.AmountOrders) }}
        </template>
        <template v-slot:cell(details)="data">
          <button
            class="btn-sm btn btn-info"
            @click="showInvoiceDetails(data.index)"
          >
            <i class="fa fa-info"></i>
          </button>
        </template>
      </b-table>
      <div
        class="alert alert-danger text-right"
        v-if="items.data && items.data.data == ''"
      >
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination
      :limit="2"
      v-if="items.data && !search"
      :data="items.data"
      @pagination-change-page="loadItems"
    >
    </pagination>
    <pagination
      :limit="2"
      v-else-if="items.data && search"
      :data="items.data"
      @pagination-change-page="searchItems"
    >
    </pagination>

    <b-modal
      id="inovice-details"
      title="جزییات صورت حساب"
      size="lg"
      hide-footer
    >
      <div>
        <table
          class="table table-bordered"
          v-if="!$root.isEmptyObject(invoice)"
        >
          <tbody>
            <tr>
              <th colspan="2">شناسه تراکنش</th>
              <td colspan="2">
                {{ invoice.transaction_id ? invoice.transaction_id : "ندارد" }}
              </td>
            </tr>
            <tr>
              <th colspan="2">وضعیت تراکنش</th>
              <td colspan="2">
                {{
                  invoice.MessageStatusTransaction
                    ? invoice.MessageStatusTransaction
                    : "ندارد"
                }}
              </td>
            </tr>
            <tr>
              <th colspan="2">وضعیت صورتحساب</th>
              <td colspan="2">
                <form id="invoice-status" @submit.prevent="invoiceStatus">
                  <b-row>
                    <b-col cols="9">
                      <b-form-group>
                        <b-form-select
                          id="status"
                          name="status"
                          v-model="invoice.status.status"
                        >
                          <b-form-select-option value="0"
                            >جدید</b-form-select-option
                          >
                          <b-form-select-option value="1"
                            >در حال انتظار</b-form-select-option
                          >
                          <b-form-select-option value="2"
                            >تایید شده</b-form-select-option
                          >
                          <b-form-select-option value="3"
                            >در حال ارسال</b-form-select-option
                          >
                          <b-form-select-option value="4"
                            >تحویل داده شده</b-form-select-option
                          >
                          <b-form-select-option value="5"
                            >کنسل شده</b-form-select-option
                          >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <button class="btn btn-primary">تغییر</button>
                    </b-col>
                  </b-row>
                </form>
              </td>
            </tr>

            <tr>
              <th colspan="2">پرداخت از:</th>
              <td colspan="2">
                {{ invoice.transaction ? invoice.transaction.gateway : "" }}
              </td>
            </tr>
            <tr>
              <th colspan="2">آدرس</th>
              <td colspan="2">{{ invoice.address }}</td>
            </tr>
            <tr>
              <th>هزینه ارسال (تومان)</th>
              <td>{{ numberFormat(invoice.delivery_amount) }}</td>
              <td>شیوه ارسال</td>
              <td>
                <span v-if="invoice.shipping_method == 'post'">پست معمولی</span>
                <span v-else-if="invoice.shipping_method == 'pishtaz'"
                  >پست پیشتاز</span
                >
                <span v-else-if="invoice.shipping_method == 'tipax'"
                  >پست تیپاکس</span
                >
              </td>
            </tr>
            <tr v-if="invoice.coupon">
              <th colspan="2">کوپن استفاده شده</th>
              <td colspan="2">
                {{ invoice.coupon.title }} - ({{ invoice.coupon.code }})
              </td>
            </tr>
            <tr>
              <th colspan="2">توضیحات سفارش</th>
              <td colspan="2">
                {{ invoice.description ? invoice.description : "ندارد" }}
              </td>
            </tr>
            <tr>
              <th colspan="2">شماره پیگیری</th>
              <td colspan="2">
                {{ invoice.tracking_code ? invoice.tracking_code : "ندارد" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-left">
          <a
            :href="'/admin/invoice/print/' + invoice.id"
            target="_blank"
            class="btn btn-info "
            ><i class="fa fa-print"></i
          ></a>
          <router-link
            :to="{ name: 'order.detail', params: { id: invoice.id } }"
            class="btn btn-info mx-1"
          >
            اقلام
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import mixins from "../mixins/mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      url: "/api/admin/sales",
      title: "فروش ها",
      invoice: {},
      table_fields: [
        { key: "id", label: "شناسه" },
        { key: "invoice_number", label: "شماره فاکتور" },
        { key: "customer", label: "نام خریدار" },
        // { key: 'coupon', label: 'کوپن' },
        { key: "AmountOrders", label: "مبلغ فاکتور (تومان)" },
        { key: "status", label: "وضعیت صورتحساب" },
        { key: "gateway", label: "پرداخت از" },
        { key: "created_at", label: "تاریخ" },
        { key: "details", label: "جزییات" },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    showInvoiceDetails(index) {
      const item = this.items.data.data[index];
      item.index = index;
      this.invoice = item;
      this.$root.$emit("bv::show::modal", "inovice-details");
    },
    invoiceStatus() {
      this.disabled = true;
      let form = document.getElementById("invoice-status");
      let formData = new FormData(form);
      this.$axios
        .post(
          this.$root.baseUrl + `/api/admin/change/status/${this.invoice.id}`,
          formData
        )
        .then((response) => {
          window.swal({
            title: "وضعیت با موفقیت تغییر کرد",
          });
          this.disabled = false;
        })
        .catch((error) => {
          this.$root.error_notification(error);
          this.disabled = false;
        });
    },
  },
  created() {
    this.searchParams = {
      invoice_number: "",
      status: "",
      start_date: "",
      end_date: "",
      customer: "",
    };
  },
};
</script>
